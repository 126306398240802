import * as _ from 'lodash'

import Person from './Person'
import httpClient from '../utils/HttpClient'

/** A Promise for the active user */
export let activeUser

/**
 *
 * @return {Promise} for the active user
 */
export function getActiveUser() {
	return Promise.resolve(activeUser)
}

export function setActiveUser(authuserId, force){
	if ((authuserId && !activeUser) || force) {
		// _.get(u, 'userData.auth_id') !== authuserId)
		activeUser = User.load(authuserId)
	}
}

/**
 * @property id
 * @property userData
 * @property orgs
 */
export default class User extends Person {

	constructor(id, userData, personId, personData) {
		super(personId, personData)
		this.id = id
		this.userData = userData || {}
		this.orgs = []
	}

	isOrgAdmin(orgId) {
		return _.get(_.find(this.orgs, {'id': orgId}), 'admin')
	}

	/**
	 *
	 * @param authUserId
	 * @returns Promise for a user and their orgs
	 */
	static load(authUserId) {
		if(!authUserId) {
			return Promise.reject('User auth id is not defined')
		}
		return new Promise((resolve, reject) => {
			if( authUserId ){
				const getUserOrgMembership = () => httpClient.get(`/api/users/${authUserId}/organizations`)
				const getUser = () => httpClient.get(`/api/users/auths/${authUserId}`)
				httpClient.all([getUserOrgMembership(), getUser()])
					.then(httpClient.spread((orgResp, userResp) => {
						let res = userResp.data
						if(res) {
							const user = new User(res.user_id, res.user_data, res.person_id, res.person_data)
							user.orgs = orgResp.data
							resolve(user)
						} else {
							reject(userResp.status)
						}
					}))
			}
		})
	}

}