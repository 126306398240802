import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { withStyles } from '@material-ui/core/styles'

import { Grid, Row, Col } from 'react-flexbox-grid'

import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'

// Icons
import Delete from '@material-ui/icons/DeleteTwoTone'

import DateInput from '../DateInput'
import IconAction from '../IconAction'

const styles = theme => ({
	input: {
		width: '100%'
	}
})

const Member = styled.div`
	background: #B3E5FC;
	padding: 0px 5px;
	margin-bottom: 5px;
	border-radius: 5px;
`
const MemberRow = styled(Row)`
	position: relative;
	padding-right: 20px;
`
const AddButton = styled(Button)`
	margin-bottom: 5px !important;
`
const ActionHolder = styled.div`
	position: absolute;
	right: 5px;
	top: 15px;
`

/**
 * Displays a list of editable family members.
 * It is up to the caller to perform exclusions (i.e. owner, current user, etc)
 */
class FamilyMembers extends React.PureComponent { // eslint-disable-line

	card(id, p, changeFunc) {

		const { classes } = this.props

		return <Member key={id}>
			<Grid fluid={true}>
				<MemberRow>
					<Col xs={6} md={6} lg={3}>
						<TextField key="firstName" type='text' label='Name' name='firstName' value={p.firstName} className={classes.input}
									 onChange={changeFunc.bind(changeFunc, 'firstName')}/>
					</Col>
					<Col xs={6} md={6} lg={3}>
						<DateInput label="Birth Date" onChange={changeFunc.bind(changeFunc, 'birthDate')} date={p.birthDate} className={classes.input} />
					</Col>
					<Col xs={6} md={6} lg={3}>
						<TextField type='email' label='Email Address' value={p.email} className={classes.input}
									 onChange={changeFunc.bind(changeFunc, 'email')}/>
					</Col>
					<Col xs={6} md={6} lg={3}>
						<TextField type='tel' label='Mobile Phone' value={p.phoneMobile} className={classes.input}
									 onChange={changeFunc.bind(changeFunc, 'phoneMobile')}/>
					</Col>
					<ActionHolder>
						<IconAction icon={<Delete/>} onClick={() => this.props.removeMember(id)} tooltip={'Remove Member'} />
					</ActionHolder>
				</MemberRow>
			</Grid>
		</Member>
	}

	render() {
		const cards = _.map(this.props.members, (person, id) => this.card(id, person || {}, this.props.onChange.bind(this, id)))
		return (
			<>
				<AddButton onClick={this.props.addMember} variant="outlined" color="primary">Add Family Member</AddButton>
				{cards}
			</>
		)
	}

	// Didn't really work well
	renderAsTable() {
		return (
			<>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>First Name</TableCell>
							<TableCell>Birth Date</TableCell>
							<TableCell>E-Mail</TableCell>
							<TableCell>Mobile Phone</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ _.map(this.props.members, (person, id) => {
							const changeFunc = this.props.onChange.bind(this, id)
							const fields = {firstName: 'text', birthDate: 'date', email: 'email', phoneMobile: 'tel'}
							const cells = _.map(fields, (v, k) => {
								if (v === 'date') {
									return <DateInput onChange={changeFunc.bind(changeFunc, k)} date={person[k]} />
								} else {
									return <TextField key={k} type={v} name={k} value={person[k]} onChange={changeFunc.bind(changeFunc, k)} />
								}
							}).map(f => <TableCell align="right">{f}</TableCell> )

							return (
								<TableRow key={id}>
									{cells}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
				<Button onClick={this.props.addMember} variant="outlined" color="primary">Add Family Member</Button>
			</>
		)
	}
}

export default withStyles(styles)(FamilyMembers)