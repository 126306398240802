import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
// Icons
import Delete from '@material-ui/icons/DeleteTwoTone'
import Edit from '@material-ui/icons/EditTwoTone'
import ViewModule from '@material-ui/icons/ViewModuleTwoTone'

import {getActiveUser} from '../../../entities/User'
import httpClient from '../../../utils/HttpClient'
import Family from '../../../entities/Family'
import EditCardDialog from '../../Directory/EditCardDialog'
import {email, phone, sms} from '../../../utils/profileElements'
import {hangSnackbarMessage} from '../../../components/ConcessionStand'
import ConfirmationDialog from '../../../components/ConfirmationDialog'


const CellText = styled.div`
	padding-left: 10px;
`
const NoWrap = styled.div`
  white-space: nowrap;
`
const Page = styled.div`
	margin: 20px;
	flexGrow: 1
`
const SectionLabel = styled.div`
	font-size: 18px;
	display: inline-block;
`
const ViewModuleIcon = styled(ViewModule)`
  width: 1em;
  height: 2em;
  vertical-align: middle;
  cursor: pointer;
`
const Filter = styled.div`
	float: right
`
const InfoMessage = styled.div`
	background-color: #FEEFB3;
	padding: 4px;
	margin: 10px;
	border-radius: 5px;
	height: 35px;
`
const PaddedTextField = styled(TextField)`
	margin-left: 5px !important;
`

const newFamily = () => ({
	firstName: '',
	lastName: '',
	email: '',
	members: [{firstName:''}]
})

/**
 * Need to be able to create a new family
 */
export default class OrganizationAdmin extends React.Component {

	constructor(props, context) {
		super(props, context)
		const {history, match} = this.props
		this.orgId = match.params.orgId

		_.bindAll(this, 'createFamily', 'updateFamily', 'confirmRemoveFamily', 'toggleEdit')
		this.gotoDirectory = () => history.push(`/organizations/${this.orgId}/directory`)

		this.state = {
			families: [],
			filtered: [],
			newFamily: newFamily(),
			activeFamily: {},
			showEditDialog: false,
			showConfirmDelete: false
		}
	}


	componentDidMount() {
		this.loadFamilies()
	}

	handleChange(name, e) {
		let newFamily = {...this.state.newFamily}
		newFamily[name] = e.target.value
		this.setState({newFamily})
	}

	familyMember(index, e) {
		let newFamily = {...this.state.newFamily}
		newFamily.members[index] = {firstName: e.target.value}
		_.size(_.last(newFamily.members).firstName) > 0 && newFamily.members.push({firstName: ''})
		this.setState({newFamily})
	}

	render() {
		if (_.isEmpty(this.state.families))
			return <div/>

		const famSize = _.size(this.state.families)
		let f = this.state.newFamily
		return <Page>

			<EditCardDialog
				family={this.state.activeFamily.family}
				active={this.state.showEditDialog}
				onSubmit={this.updateFamily}
				onClose={this.toggleEdit}
			/>

			<ConfirmationDialog key="confirmDeleteFamily" open={this.state.showConfirmDelete}
				title={'Delete Family?'}
				message={'This will remove the family from the directory.  Are you sure?'}
				buttonLabel={'Delete'}
				onCancel={() => this.setState({showConfirmDelete: false})}
				onSubmit={this.confirmRemoveFamily}
			/>

			<Grid container spacing={16}>

				<Grid item xs={12}>
					<SectionLabel>Add new family</SectionLabel>
					<Paper>
						<Grid container spacing={8} justify="center">

							<Grid item xs={6} sm={9} >
								<h4>Family Owner</h4>
								<InfoMessage>
									Use this form to create a new family.<br/>
									Their profile can be updated in the directory listing below.
								</InfoMessage>
								<PaddedTextField type='text' label='First Name' value={f.firstName} onChange={this.handleChange.bind(this, 'firstName')}/>
								<PaddedTextField type='text' label='Last Name' value={f.lastName} onChange={this.handleChange.bind(this, 'lastName')}/>
								<PaddedTextField type='email' label='Email Address' value={f.email} onChange={this.handleChange.bind(this, 'email')}/>
							</Grid>

							<Grid item xs={6} sm={3} >
								<h4>Family Members</h4>
								<InfoMessage>Enter other family members:</InfoMessage>
								{f.members.map( (m,i) => {
									return <PaddedTextField type='text' label='First Name' key={`${famSize}_${i}`} value={m.name} onChange={this.familyMember.bind(this, i)}/>
								})}
							</Grid>

							<Grid item xs={4}>
								<Button onClick={this.createFamily} variant="outlined" color="primary">Create New Family</Button>
							</Grid>
						</Grid>
					</Paper>
				</Grid>

				<Grid item xs={12}>
					<Grid container style={{alignItems: 'center'}}>
						<Grid item xs={6}>
							<Tooltip title={'Back to Directory'}><ViewModuleIcon onClick={this.gotoDirectory} /></Tooltip>
							<SectionLabel>Directory Listing</SectionLabel>
						</Grid>
						<Grid item xs={6}>
							<Filter>
								<TextField id="filter" label="Filter" type="filter" margin="normal" onChange={e => this.filter(e.target.value)} />
							</Filter>
						</Grid>
					</Grid>
					<Paper>
						{this.tableListing(this.state.filtered)}
					</Paper>
				</Grid>
			</Grid>
		</Page>
	}

	tableListing(families) {
		return <Table styles={{padding: '5px'}}>
			<TableHead>
				<TableRow>
					<TableCell>Family</TableCell>
					<TableCell>Phone</TableCell>
					<TableCell>Contact Info</TableCell>
					<TableCell>Actions</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{families.map(f => this.familyRow(f))}
			</TableBody>
		</Table>
	}

	familyRow(f) {
		const o = f.owner.personData
		const ButtonHolder = styled.span`
			margin-left: 5px
		`
		return <TableRow key={f.id} hover>
			<TableCell padding={'none'}>
				<CellText>{f.directoryLabel}</CellText>
			</TableCell>
			<TableCell padding={'none'}>
				<NoWrap>{phone(o.phoneHome || o.phoneMobile)}</NoWrap>
			</TableCell>
			<TableCell>{sms(o.phoneMobile)} {email(o.email)}</TableCell>
			<TableCell>
				<Tooltip title={'Edit Family'}>
					<ButtonHolder><Edit onClick={() => this.toggleEdit(f)} /></ButtonHolder>
				</Tooltip>
				<Tooltip title={'Remove Family'}>
					<ButtonHolder><Delete onClick={() => this.removeFamily(f.id)} /></ButtonHolder>
				</Tooltip>
			</TableCell>
		</TableRow>
	}

	// Filtering

	filter(text) {
		const filtered = this.applyFilter(this.state.families, text)
		this.setState({filtered, filter:text })
	}

	applyFilter(families, text) {
		if (_.isEmpty(text)) {
			return families
		}
		return _.filter(families, f => {
			const words = _.split(_.toLower(text), /\s|,/) //space or comma
			return _.some(f.allMemberData(), (memberObj, id) => {
				const {person_data, user_id = '', auth_id = ''} = memberObj
				const {firstName = '', lastName = '', email = ''} = person_data
				const domain = _.toLower(`${firstName} ${lastName} ${email} ${id} ${user_id} ${auth_id}`)
				return _.every(words, w => _.includes(domain, w))
			})
		})
	}

	// Loading / Create / Edit

	loadFamilies() {
		const getFamilies = () => {
			return httpClient.get(`/api/organizations/${this.orgId}/families?showUser=true`)
		}

		getActiveUser().then(u => this.setState({orgAdmin: u.isOrgAdmin(this.orgId)}))

		httpClient.all([getFamilies()])
			.then(httpClient.spread((familyResp) => {
				let rawFamilies = familyResp.data
				const families = _.map(rawFamilies, (family) => {
					const f = new Family(family)
					return f
				})
				const sortedFamilies = _.sortBy(families, f => f.directoryLabel)
				this.setState({...this.state,
					families: sortedFamilies,
					filtered: this.applyFilter(sortedFamilies, this.state.filter),
					newFamily: newFamily()})
			}))
			.catch((error) => {
				console.log(error)
			})
	}

	createFamily() {
		Family.create(this.orgId, this.state.newFamily).then(family => {
			hangSnackbarMessage({message: 'Family Created', variant: 'success'})
			this.loadFamilies()
		})
	}

	toggleEdit(family) {
		let activeFamily = this.state.activeFamily
		if (!this.state.showEditDialog) {
			activeFamily = {family}
			family.on('change', (family) => {
				const activeFamily = {family}
				this.setState({activeFamily})
			})
		}
		this.setState({showEditDialog: !this.state.showEditDialog, activeFamily})
	}

	updateFamily() {
		const {family} = this.state.activeFamily
		family.on('save', () => {
			hangSnackbarMessage({message: `${family.label} Updated`, variant: 'success'})
			this.setState({showEditDialog: false})
			this.loadFamilies()
		})
		Family.persist(family, {orgId: this.orgId})
	}

	confirmRemoveFamily() {
		const {familyId} = this.state
		this.setState({familyId: undefined, showConfirmDelete: false})
		Family.removeFromOrg(this.orgId, familyId).then(() => {
			hangSnackbarMessage({message: 'Family Removed', variant: 'success'})
			this.loadFamilies()
		})
	}
	removeFamily(familyId) {
		this.setState({familyId, showConfirmDelete: true})
	}
}


