import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'
import UpdateFamily from './UpdateFamily'

class EditCardDialog extends React.Component {

	render() {
		const {active, onClose, onSubmit, fullScreen = true, family} = this.props

		return ( !family ? <div/> :
			<>
				<Dialog
					open={active}
					fullWidth={true}
					fullScreen={fullScreen}
					maxWidth={false}
					key="edit-family-dialog">
					<DialogTitle>{family.label}</DialogTitle>
					<DialogContent>
						<UpdateFamily key="edit-family" family={family} members={family.nonOwners()} />
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color='primary'>Cancel</Button>
						<Button onClick={onSubmit} color='primary'>Save</Button>
					</DialogActions>
				</Dialog>
			</>
		)
	}
}

EditCardDialog.propTypes = {
	fullScreen: PropTypes.bool.isRequired,
}

export default withMobileDialog()(EditCardDialog)