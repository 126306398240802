import * as _ from 'lodash'
import EventEmitter from 'eventemitter3'
import httpClient from '../utils/HttpClient'

import Person from './Person'
import DateUtil from '../utils/DateUtil'

const errorHandler = (error) => {
	console.log(error)
	// Display Error in Snackbar, add to global container
}

/**
 * @property {Person} owner The person in the family marked as the owner
 * @property {Object} photo
 * @property {String} [photo.state] 'changed' | 'deleted' | undefined
 * @property {Object} [photo.file] The dropzone file object
 */
export default class Family extends EventEmitter {

	constructor(family) {
		super()
		this._family = family || {members: {}}
		this.options = {}
		this.id = this._family.id

		// Putting the id on the person makes everything else much easier
		this.rawMembers = _.mapValues(this._family.members, (member, id) => {
			member.person_id = id
			return member
		})

		const owner = _.find(_.values(this.rawMembers), ['owner', true])
		this.ownerId = _.get(owner, 'person_id')
		this.owner = new Person(this.ownerId, _.get(owner, 'person_data'))

		this._readMembers()

		this.photo = {}
		// Deprecated - should be removed once Base64 photos are gone
		this.serverPhoto = undefined

		this._directoryLabel = `${this.owner.personData.lastName} (${this.owner.personData.firstName})`
	}

	/**
	 * Get non-owners
	 *
	 * @return {Object} an id:Person mapping for each non-member
	 * @private
	 */
	_readMembers() {
		this.members = _.mapValues(this.rawMembers, member => {
			member.person_data = _.omitBy(member.person_data, (v,k) => !v)
			return member.person_data
		})
	}

	/**
	 * Includes members that are not yet persisted
	 * @return {Array<Person>}
	 */
	allMembers() {
		return Object.values(this.members)
	}
	
	/**
	 * Includes all data returned for persisted members.
	 * (e.g. user_id, auth_id, etc)
	 * Useful for search filter
	 */
	allMemberData() {
		return _.cloneDeep(this.rawMembers)
	}

	/**
	 * A current view of the members with the owner omitted.
	 */
	nonOwners() {
		return _.omitBy(this.members, (m,id) => id === this.ownerId)
	}

	getNonOwnersSorted() {
		return _.sortBy(this.nonOwners(), this.nonOwnerWeight.bind(this))
	}

	nonOwnerWeight(p) {
		return this.getAgeBasedWeight(p, DateUtil.yearsOld(p.birthDate))
	}

	/**
	 * Try to float adults to the top of the list and children in order by age.
	 * But if someone has contact information that get's priority
	 */
	getAgeBasedWeight(p, yearsOld) {
		const adult = yearsOld > 18
		// 18 weighted as 0-1
		const youthFactor = 1 - ( (18 - yearsOld) / 18)
		const weight =
			(p.email ? 1 : 0) +
			(p.phoneMobile ? 1 : 0) +
			(adult ? 1 : youthFactor)
		p.weight = weight > 0 ? (-1 * weight) : weight
		return p.weight
	}

	isEmpty() {
		return _.isEmpty(this.members)
	}

	/**
	 *
	 * @param {UUID} personId
	 * @param {boolean} [opts.optimistic=false] If true, return true when this.ownerId is not yet defined.  Prevents components
	 * from rendering that are displayed when isOwner=false before the XHR is available
	 *
	 * @return {boolean}
	 */
	isOwner(personId, opts = {optimistic: false}) {
		// If an ownerId is not yet initialized just say true so we don't get false-positive things showing up before the
		// data is fetched
		if (opts.optimistic && !this.ownerId) {
			return true
		}
		return this.ownerId !== undefined && personId === this.ownerId
	}

	/**
	 * A label for the family intended when the family is displayed outside a directory.
	 * If a family is a single member this will be their name, otherwise it will be 'The <lastName>s'
	 * @return {string|*}
	 */
	get label() {
		if (_.size(this.members) === 1) {
			return this.owner.label
		} else {
			return `The ${this._family.label}s`
		}
	}

	/**
	 * A directory label that is suitable for a lexical sort based on last name
	 * @return {string}
	 */
	get directoryLabel() {
		return this._directoryLabel
	}

	get pictureId() {
		return _.get(this._family.photos, 'current.filename')
	}

	/**
   * Get the primary address from the owner of the family
   *
   * @return {Object}
   */
	get primaryAddress() {
		return this.owner.primaryAddress || {}
	}

	setOwnerAttribute(attr) {
		_.each(attr, (v, k) => {
			if (_.startsWith(k, 'opt_')) {
				this.options[_.trimStart(k,'opt_')] = v
			} else {
				this.owner.personData[k] = v
				this.setFamilyMemberAttribute(this.ownerId,attr)
			}
		})
		this.emit('change', this)
	}

	/**
	 *
	 * @param {string} personId uuid
	 * @param {object} attr {name:value}
	 */
	setFamilyMemberAttribute(personId, attr) {
		const person = {...this.members[personId]}
		_.each(attr, (v, k) => person[k] = v)
		this.members[personId] = person
		this.members = _.cloneDeep(this.members)
		this.emit('change', this)
	}

	addMember() {
		const id = _.size(this.members) - 1
		this.members[id] = {}
		this.emit('change', this)
	}

	removeMember(id) {
		delete this.members[id]
		this.emit('change', this)
	}

	setPhoto(file){
		this.photo = {state: 'changed', file}
		this.emit('change', this)
	}

	deletePhoto() {
		this.photo = {state: 'deleted'}
		delete this.pictureUrl
		delete this.serverPhoto
		this.emit('change', this)
	}

	imgSrc() {
		return _.get(this.photo, 'file.preview', this.pictureUrl || this.serverPhoto)
	}

	setOwner(callback) {
		httpClient.post('/api/families/owner').then( resp => {
			if (resp.status === 200 && resp.data.owner) {
				callback(resp.data)
			}
		})
	}

	/**
	 *
	 * @param orgId
	 * @param familyData.owner
	 * @param familyData.members
	 */
	static create(orgId, familyData) {
		const {members, ...owner} = familyData
		return httpClient.post(`/api/organizations/${orgId}/families`, {data: {owner, members}}).catch(errorHandler)
	}
	/**
	 * @param {Family} family
	 * @param {String} [options.orgId] if provided, the admin route will be used
	 */
	static persist(family, options = {}) {
		const {orgId, personId, personData} = options
		const ownerId = personId || family.ownerId
		const ownerData = personData || family.owner.personData

		// Admins use /organizations/people/id protected by Role Permission
		// Non-admins can only update their own profile, which uses their principal from the request
		const route = id => orgId ? `/api/organizations/${orgId}/families/${family.id}` : `/api/people/${id}`

		if (ownerId) {
			const {file, state} = _.get(family, 'photo')
			const xhrBody = {
				data: {
					personId: ownerId,
					personData: ownerData,
					members: family.nonOwners()
				},
				options: { photo: {state} }
			}
			const operations = [httpClient.put(`${route(ownerId)}`, xhrBody)]

			// Update the photo if a new one was selected by the user
			if(file) {
				const formData = new FormData()
				formData.append('directoryPhoto', file)
				operations.push(httpClient.post(`${route(ownerId)}/photos`, formData))
			}

			httpClient.all(operations)
				.then(httpClient.spread((personResponse, photoResponse) => {
					family && family.emit('save')
				}))
				.catch(errorHandler)
		}
	}

	static removeFromOrg(orgId, familyId) {
		return httpClient.delete(`/api/organizations/${orgId}/families/${familyId}`).catch(errorHandler)
	}
}