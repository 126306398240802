import httpClient from '../utils/HttpClient'

export default {

	fetch() {
		return httpClient.get('/api/client-data').then(res => {
			const {auth, mode, config = {}} = res.data
			this.production = mode === 'production'
			this.auth = auth
			this.config = config
			return this
		})
	},

	getAuth() {
		return this.auth
	},

	isProduction() {
		return this.production
	}
}
