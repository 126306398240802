import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { DatePicker } from 'material-ui-pickers'
import { withTheme } from '@material-ui/core/styles'

const Picker = styled.div`
	min-width: 150px;
`

class DateInput extends React.Component {

	render() {

		const dateChange = date => {
			this.props.onChange({target: {value: date}})
		}

		const {label, date} = this.props

		// undefined causes the picker to select the current date
		// We want null so it is empty
		const pickerDate = _.isUndefined(date) ? null : date
		return (
			<Picker>
				<DatePicker
					keyboard
					allowKeyboardControl
					clearable
					disableOpenOnEnter
					disableFuture
					label={label}
					format={'MM/dd/yyyy'}
					placeholder="10/10/2018"
					mask={value =>
						value ? [/\d/,/\d/, '/',/\d/, /\d/,'/',/\d/,/\d/,/\d/,/\d/] : []
					}
					value={pickerDate}
					onChange={dateChange}
					animateYearScrolling={false}
				/>
			</Picker>
		)
	}
}

export default withTheme()(DateInput)