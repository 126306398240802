import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

function ConfirmationDialog(props) {

	const {open, onCancel, onSubmit, title, message, buttonLabel} = props

	return (
		<>
			<Dialog
				open={open}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={onSubmit} color="primary" autoFocus>
						{buttonLabel}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default ConfirmationDialog