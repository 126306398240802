import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'


import Card from './Card'


/**
 * A dialog that displays a single directory card in full screen mode.
 */
class SingleCardDialog extends React.Component {

	render() {
		const {active, onClose, fullScreen, family} = this.props
		const f = family

		return (
			<>
				<Dialog
					open={active}
					fullWidth={true}
					fullScreen={fullScreen}>
					<DialogTitle>{f.label}</DialogTitle>
					<DialogContent>
						<Card family={f}
							imgSrc={f.pictureUrl}
							pictureOnTop={true}
							editable={false}
							single={true}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={onClose} color='primary'>Close</Button>
					</DialogActions>
				</Dialog>
			</>
		)
	}
}

SingleCardDialog.propTypes = {
	family: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	fullScreen: PropTypes.bool
}

SingleCardDialog.defaultProps = {
	fullScreen: false
}

export default withMobileDialog()(SingleCardDialog)