import React from 'react'
import styled from 'styled-components'

import Email from '@material-ui/icons/Email'
import Textsms from '@material-ui/icons/Textsms'
import DirectionsIcon from '@material-ui/icons/DirectionsTwoTone'

import {mapUrl} from '../components/Address/Address'


const TextAnchor = styled.a`
	margin-left: ${props => props.options.noMargin ? '0px' : '5px'};
`

const IconAnchor = styled.a`
	position: relative;
	top: 5px;
	padding-left: 5px;
	
`


export function email(email) {
	return email ? <IconAnchor href={`mailto:${email}`}><Email /></IconAnchor> : <span />
}

function formatPhone(phoneNumber) {
	return phoneNumber && phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export function phone(phoneNumber, options = {}) {
	const p = formatPhone(phoneNumber)
	return phoneNumber ? <TextAnchor href={`tel:${p}`} options={options}>{p}</TextAnchor> : <span />
}

export function sms(phoneNumber, options = {}) {
	const p = formatPhone(phoneNumber)
	return phoneNumber ? <IconAnchor style={{color: 'limegreen'}} href={`sms:${p}`}><Textsms /></IconAnchor> : <span />
}

export function directions(address) {
	if (address) {
		return <IconAnchor href={mapUrl(address)} style={{color: 'indianred'}} target="_blank"><DirectionsIcon/></IconAnchor>
	}
	return <span />
}





