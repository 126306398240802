import React from 'react'
import styled from 'styled-components'

import Person from '../../components/Person'

const PersonForm = styled.div`
`

/**
 * TODO: Changee this back to a PureComponent and implement shouldComponentUpdate using immutable
 * https://60devs.com/pure-component-in-react.html
 */
export default class UpdateFamily extends React.Component {

	render() {
		const f = this.props.family
		return (
			<PersonForm>
				<Person
					family={f}
					person={f.owner}
					options={f.options}
					familyMembers={f.nonOwners()}
					onChange={f.setOwnerAttribute.bind(f)}
					addMember={f.addMember.bind(f)}
					removeMember={f.removeMember.bind(f)}
					onFamilyMemberChange={f.setFamilyMemberAttribute.bind(f)}
					onFile={f.setPhoto.bind(f)}
				/>
			</PersonForm>
		)
	}
}
