import React, {Fragment} from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import styled from 'styled-components'


const ActionIconHolder = styled.div`
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 2px;
	padding: 0px 2px;
`

const Label = styled.span`
	position: relative;
	bottom: 7px;
`

/**
 * Renders an Icon with an optional label and tooltip.
 * Centralizes css for aligning the label and icon.
 *
 * @param {Component} props.icon A react component that displays an Icon, typically a react-ui/icon
 * @param {String} [props.label] Will be displayed to the right of the icon
 * @param {String} [props.tooltip] Will generate a tooltip with this text
 */
function IconAction(props) {
	
	const {onClick, icon, label, tooltip} = props
	
	const iconAction = (
		<ActionIconHolder onClick={onClick} >
		{icon} <Label>{label}</Label>
		</ActionIconHolder>
	)
	
	return tooltip ? <Tooltip title={tooltip}>{iconAction}</Tooltip> : iconAction
	
}

export default IconAction