/**
 * Every other view goes inside of this one, in the {children} placeholder
 * This is where we will add all headers, left nav, etc
 */
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import IconButton from '@material-ui/core/IconButton'

// Icons
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'

import AuthService from '../utils/AuthService'

const AppLabel = styled(Typography)`
	flex-grow: 1;
`

export default class Container extends React.Component {
	
		state = {
			auth: true,
			anchorEl: null,
		}
	
		constructor(props, context) {
			super(props, context)
			this.auth = this.props.auth
			this.auth.on('profile_updated', (newProfile) => {
				this.setState({profile: newProfile})
			})
		}

		// Navigation
		logout = () => {
			AuthService.logout()
			this.props.history.push('/login')
		}

		goHome = () => {
			this.props.history.push('/')
			this.handleClose()
		}

		docs = () => {
			this.props.history.push('/doc')
			this.handleClose()
		}

		retry() {
			this.auth.reloadProfile(this.props.history)
			this.props.history.push('/')
		}
	
		// Menu
		handleChange = event => {
			this.setState({ auth: event.target.checked })
		}
		
		handleMenu = event => {
			this.setState({ anchorEl: event.currentTarget })
		}
		
		handleClose = () => {
			this.setState({ anchorEl: null })
		}
	
		render() {
			const { auth, anchorEl } = this.state
			const open = Boolean(anchorEl)
			
			let children = null
			let profile = {}
			if (this.props.children) {
				children = React.cloneElement(this.props.children, {
					auth: this.auth //sends auth instance to children
				})
				profile = AuthService.getProfile()
			}

			// Must use this.props.auth
			const menu = AuthService.loggedIn() &&
			<>
				<IconButton color="inherit" aria-label="Menu" onClick={this.handleMenu}>
					<MenuIcon />
				</IconButton>
				<Menu
					id="menu-appbar"
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={open}
					onClose={this.handleClose}
				>
					<MenuItem onClick={this.goHome}>Home</MenuItem>
					<MenuItem onClick={this.docs}>Help</MenuItem>
					<MenuItem onClick={this.logout}>Logout</MenuItem>
				</Menu>
			</>
			
			const navigation = AuthService.loggedIn() && <div onClick={this.goHome} color="inherit"><AccountCircle /></div>
			

			const Header = () => {
				return (
				<AppBar position="static">
					<Toolbar>
						{menu}
						<AppLabel variant="h6" color="inherit">
							Pictorial Directory
						</AppLabel>
						{navigation}
					</Toolbar>
				</AppBar>
				)
				// return <MyAppBar title="Pictorial Directory" position={'sticky'}>{navigation}</MyAppBar>
			}

			if (profile.email_verified) {
				return (
					<div>
						<Header />
						{children}
					</div>
				)
			} else {
				if (profile.sub) {
					return <div>
						<div>You must activate your account first, please check your e-mail.</div>
						<Button onClick={this.retry.bind(this)}>Ok I verified it, let me in</Button>
					</div>
				} else {
					return (
						<div>
							<Header/>
							{children}
						</div>
					)
				}
			}
		}
}