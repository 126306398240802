import _ from 'lodash'
import React from 'react'
import {Grid, Row, Col} from 'react-flexbox-grid'
import Dropzone from 'react-dropzone'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'

import Address from '../Address'
import FamilyMembers from '../Family/FamilyMembers'

import styled from 'styled-components'
import DateInput from '../DateInput'

const FileDrop = styled(Dropzone)`

`

const FileTarget = styled.div`
	height: 100%;
	width: 100%;
	min-width: 100px;
	min-height: 100px;
	text-align: center;
	vertical-align: middle;
	
	margin-top: 20px;
	border-width: 2px;
	border-color: rgb(102, 102, 102);
	border-style: dashed;
	border-radius: 5px;
	background-color: #B3E5FC;
	
`

const ImgPreview = styled.img`
	max-width: 98%;
	object-fit: contain;
	margin: auto;
	display: block;
`

const OwnerWarning = styled.div`
	color: #9F6000;
	background-color: #FEEFB3;
	padding: 15px;
	border-radius: 5px;
	line-height: 1.5;
`

const DeleteFileButton = styled(Button)`
	margin-top: 10px !important;
	margin-bottom: 10px !important;
	margin-left: calc(50% - 100px) !important;
	width: 200px;
`

const WideTextField = styled(TextField)`
	width: 100%;
`

export default class Person extends React.Component { // eslint-disable-line

	constructor(props) {
		super(props)
		_.bindAll(this, ['handleFamilyMemberChange'])
	}

	handleChange(name, value) {
		this.props.onChange({[name]: value.target.value})
	}

	handlePrimaryAddressField(field, value) {
		const a = _.get(this.props.person, 'personData.primaryAddress', {})
		var updated = {...a}
		updated[field] = value
		this.props.onChange({primaryAddress: updated})
	}

	handleFamilyMemberChange(id, field, evt) {
		const value = evt.target.value
		this.props.onFamilyMemberChange(id, {[field]: value})
	}

	onFileDrop(acceptedFiles, rejectedFiles) {
		console.log('Accepted files: ', acceptedFiles)
		console.log('Rejected files: ', rejectedFiles)
		acceptedFiles.forEach((file) => {
			this.props.onFile(file)
		})
	}

	/*
	<Col xs={12} md={6}>
		<Checkbox label="replace user (login) email address? (forces logout)"
							checked={opts.replaceUserEmail}
							onChange={this.handleChange.bind(this, 'opt_replaceUserEmail')}
		/>
	</Col>
	 */

	render() {
		const {person, family} = this.props
		const personData = person.personData
		const isOwner = family.isOwner(person.personId)
		const canDeletePhoto = family.imgSrc() && isOwner
		const deletePhotoButton = canDeletePhoto
			? <DeleteFileButton onClick={() => family.deletePhoto()} variant="outlined" color="primary">Remove Photo</DeleteFileButton>
			: <div/>

		// Omit the current person from the list of members
		const familyMembers = _.omitBy(family.members, (m, personId) => personId === person.personId)
		const familyMemberSection = !isOwner ? <div/> : <div>
			<h3>Family Member Information</h3>
			<FamilyMembers key="family-members"
				members={familyMembers}
				onChange={this.handleFamilyMemberChange}
				addMember={this.props.addMember}
				removeMember={this.props.removeMember} />
		</div>

		return (
			<Grid key="person-form" fluid={true}>
				<Row>
					<Col xs={12} md={6} >

						{this.displayOwnershipTakeover(this.props.family, person.personId)}

						<h3>Personal Information</h3>

						<Grid fluid={true}>
							<Row>
								<Col xs={6} md={6}>
									<WideTextField type='text' label='First Name' name='firstName' value={personData.firstName || ''}
												 onChange={this.handleChange.bind(this, 'firstName')}/>
								</Col>
								<Col xs={6} md={6}>
									<WideTextField type='text' label='Last Name' name='lastName' value={personData.lastName || ''}
												 onChange={this.handleChange.bind(this, 'lastName')}/>
								</Col>
							</Row>

							<Row>
								<Col xs={12} md={6}>
									<WideTextField type='email' label='Email Address' value={personData.email || ''}
												 onChange={this.handleChange.bind(this, 'email')}/>
								</Col>
							</Row>

							<Row>
								<Col xs={6} md={4} lg={3}>
									<WideTextField type='tel' label='Mobile Phone' value={personData.phoneMobile || ''}
												 onChange={this.handleChange.bind(this, 'phoneMobile')}/>
								</Col>
								<Col xs={6} md={4} lg={3}>
									<WideTextField type='tel' label='Home Phone' value={personData.phoneHome || ''}
												 onChange={this.handleChange.bind(this, 'phoneHome')}/>
								</Col>
								<Col xs={6} md={4} lg={3}>
									<DateInput onChange={this.handleChange.bind(this, 'birthDate')} date={personData.birthDate} label="Birth Date" />
								</Col>
							</Row>

						</Grid>

						<Address key="primaryAddress" name="primaryAddress" label="Primary Address"
										 value={personData.primaryAddress} onChange={this.handlePrimaryAddressField.bind(this)}/>

						{familyMemberSection}
					</Col>

					<Col xs={12} md={6}>
						<h3>Family Photo</h3>
						{this.photo(family.imgSrc(), isOwner)}
						{deletePhotoButton}
					</Col>
				</Row>
			</Grid>
		)
	}

	photo(imgSrc, isOwner) {

		if (isOwner) {
			const fileContent = imgSrc ? <ImgPreview src={imgSrc}/> :
				<FileTarget>
					<h3>Update Directory Photo</h3>
					<span>(Click or Drag & Drop)</span>
				</FileTarget>

			return (
				<FileDrop onDrop={this.onFileDrop.bind(this)} multiple={false} accept={'image/*'}>
					{fileContent}
				</FileDrop>
			)

		} else {
			return imgSrc ? <ImgPreview src={imgSrc}/> : <div/>
		}
	}

	displayOwnershipTakeover(family, personId) {
		if (!family.isOwner(personId, {optimistic: true})) {
			return <OwnerWarning>
				You are not currently the owner for your family.<br/>
				Take ownership to display your profile information on your directory card.
				<div>
					<Button onClick={this.props.takeOwnership}>Make me the owner</Button>
				</div>
			</OwnerWarning>
		} else {
			return <div/>
		}
	}

}