// Lodash is a utility library for all kinds of common data structure and object related tasks
import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

// Axios is the HTTP library we use to talk to the API Server
import httpClient from '../../utils/HttpClient'

// We use relative paths to our own modules
import AuthService from '../../utils/AuthService'
import Person from '../../components/Person'
import User, {getActiveUser} from '../../entities/User'

import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Family from '../../entities/Family'
import {hangSnackbarMessage} from '../../components/ConcessionStand'


const ProfileDiv = styled.div`
	margin-left: 10px;
	margin-right: 10px;
`

const SaveButton = styled(Button)`
	margin-left: calc(50% - 150px) !important;
	margin-bottom: 15px !important;
	width: 300px;
`

/**
 * Every component has to extend from a React.Component
 */
export class Home extends React.Component {
	//TODO: Document why these are important
	static contextTypes = {
		router: PropTypes.object
	}
	static propTypes = {
		auth: PropTypes.instanceOf(AuthService)
	}

	constructor(props) {
		super(props)
		// this.state is the single object where all component state is stored.
		// NEVER assign to it directly except for right in the constructor
		this.state = {
			// We set some sensible defaults so we don't get undefined errors when
			// the page renders before there is data loaded
			orgs: [{data: {name: 'Loading...'}}],
			user: new User(),
			family: new Family(),
			dirty: false
		}
	}

	// React Functions

	/**
	 * This is invoked once, unlike render which is invoked whenever the state changes
	 * Data fetching is done here for this reason
	 */
	componentDidMount() {
		this._loadOrgsAndUser()
	}

	// This is what we want to go into the DOM, render will get invoked automatically
	// anytime this component's state changes
	render() {
		const f = this.state.family

		const message = <div>It seems you don't belong to any organizations, please double check
			that you logged in with the e-mail address that is used in your directory.
			If you are still having trouble please contact <a href="mailto:support@pictoriald.com">support</a> for assistance.
		</div>


		const missingOrgMessage = _.isEmpty(this.state.orgs) ? message : undefined
		return (
			<ProfileDiv>
				{/* This is not HTML, it is JSX - a special thing about React :) */}
				{/*<StyledButton onClick={this.createOrg.bind(this)}>Create Organization</StyledButton>*/}
				{/*<StyledButton onClick={this.joinOrg.bind(this)}>Join Organization</StyledButton>*/}

				<h3>Your Directories</h3>
				<List>
					{this.state.orgs.map((o, i) =>
						<ListItem key={i} onClick={this.viewOrg.bind(this, o)} button >
								<ListItemText>{o.data.name}</ListItemText>
						</ListItem>
					)}
				</List>

				{missingOrgMessage}

				<h3>Your Profile</h3>
				<div className="profile-container">
					<Person userId={this.state.user.id}
						person={this.state.user}
						options={f.options}
						family={f}
						onChange={this.setUserAttribute.bind(this)}
						onFamilyMemberChange={f.setFamilyMemberAttribute.bind(f)}
						addMember={f.addMember.bind(f)}
						removeMember={f.removeMember.bind(f)}
						onFile={f.setPhoto.bind(f)}
						takeOwnership={() => f.setOwner(() => this._loadOrgsAndUser())}
					/>
				</div>
				<SaveButton onClick={this.saveProfile.bind(this)} variant="contained" color="primary" >Save Profile</SaveButton>
			</ProfileDiv>

		)
	}

	// Supporting Functions

	_loadOrgsAndUser() {
		// this.props gives us access to the attributes that were set on the component when it was declared
		// Right here, we are looking at the 'auth' property that was defined in routes.js
		getActiveUser().then(user => {
			if (user) {
				this.props.auth.orgs = user.orgs // Global state (available orgs)

				httpClient.all([
					httpClient.get(`api/people/${user.personId}/photo`),
					httpClient.get(`api/people/${user.personId}/familyMembers`)
				]).then(httpClient.spread((photoResp, familyResp) => {
					const family = new Family({members: familyResp.data})
					if (photoResp.data.photoData) {
						family.serverPhoto = 'data:image/png;base64, ' + photoResp.data.photoData
					}
					family.on('change', family => this.setState({family}))
					this.setState({...this.state, user, orgs: user.orgs, family})

				})).catch((error) => {
					console.log(error)
					// Even if something failed, set the state we do have
					// We use setState to assign new state
					this.setState({...this.state, user, orgs: user.orgs})
				})
			}
		})
	}

	// Action Handlers

	viewOrg(userOrgRelation) {
		this.props.history.push(`/organizations/${userOrgRelation.id}/directory`)
	}

	/**
	 * A callback anytime a form attribute changes for the Person component
	 * @param {object} attr {name:value}
	 */
	setUserAttribute(attr) {
		// {...Object} is called a 'destructuring assignment', and it lets us easily
		// create a copy of an object before changing some of it's properties.
		// Remember, NEVER change anything on this.state
		const user = {...this.state.user}
		_.each(attr, (v, k) => user.personData[k] = v)
		// we can just pass the partial new state
		this.setState({user})
	}

	saveProfile() {
		const {family} = this.state
		const opts = {
			personId: this.state.user.personId,
			personData: this.state.user.personData
		}
		family.on('save', () => hangSnackbarMessage({message: 'Profile Updated', variant: 'success'}))
		Family.persist(family, opts)
	}
}

export default Home
