import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'

import Button from '@material-ui/core/Button'

import AuthService from '../../utils/AuthService'
import clientData from '../../utils/clientData'
import styles from './styles.module.css'

import styled from 'styled-components'

const LoginButton = styled(Button)`
  float: none !important;
  width: 200px;
  // margin-top: 100px;
`

const LoginBanner = styled.div`
	margin-top: 25px;
	padding: 5px;
	margin-left: auto;
	margin-right: auto;
	min-width: 200px;
	width: 300px;
	white-space: pre-line;
	background-color: lightskyblue;
	border-radius: 5px;
`

const ImgHolder = styled.div`
	margin-top:20px;
`

export class Login extends React.Component {
	
	static propTypes = {
		location: PropTypes.object,
		auth: PropTypes.instanceOf(AuthService)
	}
	
	componentWillMount() {
		if (this.props.logout) {
			AuthService.logout()
			this.props.history.replace('/login') // A hack until I figure out how to get the router to apply the `logout=true` to the container rendering
		}
	}
	
	render() {
		const loginPage = <div className={styles.root}>
			{this.banner()}
			<ImgHolder>
				<img src="/logo" alt="logo" />
			</ImgHolder>
			<LoginButton onClick={() => this.startLogin()} variant="contained" color="primary">Login</LoginButton>
		</div>
		
		// During login Auth0 redirects and we lose all state, this prevents the login component from flickering briefly
		if (_.includes(this.props.location.hash, 'access_token')) {
			this.props.auth.handleAuthentication(this.props.history)
			return <div />
		}
		
		// Once all the XHR crap is done and we have a token we can go to the home page
		if (AuthService.loggedIn()) {
			return <Redirect to={'/home'} />
		}
		
		// If user is not logged in and we didn't just come from Auth0 they have to login
		return loginPage
	}
	
	banner() {
		if (clientData.config.loginBanner) {
			return <LoginBanner>
				{clientData.config.loginBanner}
			</LoginBanner>
		}
		return <div/>
	}
	
	startLogin() {
		const {auth} = this.props
		auth.login()
	}
}

export default Login
