import * as _ from 'lodash'

/**
 * @property {String} personId
 * @property {Object} personData
 */
export default class Person {

	constructor(id, personData){
		this.personId = id
		this.personData = _.omitBy(personData, _.isEmpty) || {}
		let a = this.personData.primaryAddress
		this.personData.primaryAddress = a || {}
		this._label = `${this.personData.firstName} ${this.personData.lastName}`
	}

	get label() {
		return this._label
	}
}