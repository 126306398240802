// Axios is the HTTP library we use to talk to the API Server
import axios from 'axios'

axios.interceptors.request.use(function (config) {
	const token = localStorage.getItem('id_token')
	config.headers['Authorization'] = 'Bearer ' + token
	return config
})

// const instance = axios.create({
// baseURL: 'https://some-domain.com/api/',
// timeout: 1000,
// headers: {'X-Custom-Header': 'foobar'}
// })

export default axios