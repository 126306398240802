import React from 'react'
import PropTypes from 'prop-types'

import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'

export default class Footer extends React.Component {

	constructor() {
		super()
		this.onValueChange = this.changeValue.bind(this)
		this.state = {
			selectedValue: ''
		}
	}

	componentDidMount() {
		this.setState({selectedValue: this.props.value})
	}

	changeValue(event, value) {
		this.setState({selectedValue: value})
		this.props.onChange(value)
	}

	render() {
		return <BottomNavigation showLabels
			value={this.state.selectedValue}
			onChange={this.onValueChange}>
			{this.props.items.map((a,k) => <BottomNavigationAction key={k} label={a.label} value={a.value} icon={a.icon} />)}
		</BottomNavigation>
	}
}

Footer.propTypes = {
	/** called with the Nav options's value when selected */
	onChange: PropTypes.func,
	/** BottomNav objects to render */
	items: PropTypes.array
}

export class BottomNav {
	constructor(label, value, icon){
		this.label = label
		this.value = value
		this.icon = icon
	}
}