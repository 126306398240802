import React from 'react'
import TextField from '@material-ui/core/TextField'
import {Col, Grid, Row} from 'react-flexbox-grid'
import styled from 'styled-components'

const AddressSection = styled.div`
	border-radius: 10px;
`
const WideTextField = styled(TextField)`
	width: 100%;
`

export default class Address extends React.PureComponent { // eslint-disable-line

	handleChange(name, e) {
		this.props.onChange(name, e.target.value)
	}

	render() {
		const a = this.props.value || {}
		const url = mapUrl(a)
		// const destUrl = `https://www.google.com/maps/dir/?api=1&destination=${encAddress}`
		if (this.props.unlabeled) {
			return (
				<AddressSection>
					<a href={url} target="_blank" >
						<div>{a.street}</div>
						<div>{a.city}, {a.state} {a.zip}</div>
					</a>
				</AddressSection>
			)
		} else {
			// const TextControl = (props) => <Input type='text' {...props} />
			// let AddressField = TextControl
			return (
				<Grid fluid={true}>
					<Row>
						<Col xs={12} md={6}>
							<WideTextField name="street" label="Street" value={a.street || ''}
										 onChange={this.handleChange.bind(this, 'street')}/>
						</Col>
						<Col xs={7} md={3}>
							<WideTextField name="city" label="City" value={a.city || ''}
										 onChange={this.handleChange.bind(this, 'city')}/>
						</Col>
						<Col xs={2} md={1}>
							<WideTextField name="state" label="State" value={a.state || ''} maxLength={2}
										 onChange={this.handleChange.bind(this, 'state')}/>
						</Col>
						<Col xs={3} md={2}>
							<WideTextField name="zip" label="Zip Code" value={a.zip || ''}
										 onChange={this.handleChange.bind(this, 'zip')}/>
						</Col>
					</Row>
				</Grid>
			)
		}
	}
}

/**
 * Given an address return the google maps url
 */
export function mapUrl(address = {}) {
	const {street, city, state, zip} = address
	const encAddress = encodeURIComponent(`${street} ${city}, ${state} ${zip}`)
	return `https://www.google.com/maps/search/?api=1&query=${encAddress}`
}