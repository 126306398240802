import _ from 'lodash'
import {parse, parseISO, getYear, differenceInYears} from 'date-fns'

const thisYear = getYear(new Date()) - 2000

export default class DateUtil {
	
	/**
	 * Given a birth date in either ISO or mm/dd/yy[yy] format
	 * return how many years old the person is.  Assuming they're less than 99.
	 *
	 * @param {String} birthDate
	 * @param {Number} [baseYear=current year] the year since 2000 to use for comparison (e.g. 19)
	 * @return {number}
	 */
	static yearsOld(birthDate, baseYear = thisYear) {
		
		if (!birthDate) {
			return 0
		}
		
		let fnsDate = parseISO(birthDate)
		if (isNaN(fnsDate.getDate())) {
			let [month, day, year] = _.split(birthDate, '/')
			// If a date is only YY then it could be 19xx or 20xx.
			if (year < 100 ) {
				year = year > baseYear ? `19${year}` : `20${year}`
			}
			fnsDate = parse(`${month}/${day}/${year}`, 'MM/dd/yyyy', new Date())
		}
		const delta = differenceInYears(new Date(), fnsDate)
		const yearsOld = Math.floor(Math.abs(delta))
		
		return _.isNaN(yearsOld) ? 0 : yearsOld
	}
}