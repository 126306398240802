import {EventEmitter} from 'events'

import _ from 'lodash'
import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'
import Raven from 'raven-js'

import httpClient from '../utils/HttpClient'
import {setActiveUser} from '../entities/User'
import clientData from './clientData'

/**
 * Handles everything related to the user authentication and authorization
 * on the client.
 *
 * This includes token management and permissions.
 */
export default class AuthService extends EventEmitter {

	constructor(domain, clientId) {
		super()
		this.login = this.login.bind(this)
		this.handleAuthentication = this.handleAuthentication.bind(this)
		this._orgs = []

		const authConf = clientData.getAuth()
		this.auth0 = new auth0.WebAuth({
			domain: authConf.domain,
			clientID: authConf.clientId,
			redirectUri: `${window.location.origin}/login`,
			audience: `https://${authConf.domain}/userinfo`,
			responseType: 'token id_token',
			//http://openid.net/specs/openid-connect-core-1_0.html#ScopeClaims
			scope: 'openid email profile'
		})
	}
	
	login() {
		this.auth0.authorize()
	}

	handleAuthentication(history) {
		this.auth0.parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				AuthService.setSession(authResult)
				this.loadProfile(history)
			} else if (err) {
				Raven.captureException(err)
				console.warn(err)
			}
		})
	}

	loadProfile(history){
		this.getAuth0Profile((error, profile) => {
			if (error) {
				console.log('Error loading the Auth0 Profile', error)
			} else {
				// Create a user and person if they don't exist
				const authUserId = profile.sub
				// Get the user first
				this.fetchAppUser(authUserId, history)
			}
		})
	}

	getAuth0Profile(cb) {
		let accessToken = AuthService.getAccessToken()
		this.auth0.client.userInfo(accessToken, (err, profile) => {
			// https://auth0.com/docs/user-profile/user-profile-structure
			if (profile) {
				this.setProfile(profile)
			}
			cb(err, profile)
		})
	}

	fetchAppUser(authUserId, history) {
		httpClient.get(`/api/users/auths/${authUserId}`)
			.then(this._addUserIfNotPresent.bind(this))
			.catch(err => {
				const code = err.response.status
				if (code === 403) { // Can't authorize a user that doesn't exist.
					return this._addUserIfNotPresent({})
				}
			})
			.then(() => {
				// Just created the user so have to force fetching orgs + user info
				setActiveUser(authUserId, true)
			})
			.then(() => {
				history.push('/home')
			})
	}

	/**
	 * If user doesn't exist add them
	 * @param userResp
	 * @return {AxiosPromise|*}
	 * @private
	 */
	_addUserIfNotPresent(userResp) {
		if( _.isEmpty(userResp.data) ){
			var profile = _.cloneDeep(AuthService.getProfile())
			if(!profile.user_id) {
				// After switching to Auth0's Lock 10 api user_id is no more
				// https://auth0.com/docs/tokens/id-token#id-token-payload
				profile.user_id = profile.sub
			}
			return httpClient.post('/api/users', profile)
		}
	}

	// Client Session Management
	
	static logout() {
		// Clear access token and ID token from local storage
		localStorage.removeItem('profile')
		localStorage.removeItem('access_token')
		localStorage.removeItem('id_token')
		localStorage.removeItem('expires_at')
	}
	
	static setSession(authResult) {
		// Set the time that the access token will expire at
		const token = authResult.idToken
		const decoded = jwtDecode(token)
		const futureTime = decoded.exp * 1000
		localStorage.setItem('access_token', authResult.accessToken)
		localStorage.setItem('id_token', token)
		localStorage.setItem('expires_at', JSON.stringify(futureTime))
	}
	
	static getAccessToken() {
		// Retrieves the user token from localStorage
		return localStorage.getItem('access_token')
	}
	
	static loggedIn() {
		// Check whether the current time is past the
		// access token's expiry time
		let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
		return new Date().getTime() < expiresAt
	}

	setProfile(profile) {
		const {sub, email} = profile
		Raven.setUserContext({id: sub, email})
		// Saves profile data to localStorage
		localStorage.setItem('profile', JSON.stringify(profile))
		// Triggers profile_updated event to update the UI
		this.emit('profile_updated', profile)
	}
	
	static getProfile() {
		// Retrieves the profile data from localStorage
		const profile = localStorage.getItem('profile')
		const p = profile ? JSON.parse(localStorage.profile) : {}
		setActiveUser(p.sub)
		return p
	}

	reloadProfile(history) {
		this.loadProfile(history)
	}

	_authorizationError(error) {
		// Unexpected authentication error
		console.log('Authentication Error', error)
	}

	// Authorization / Entitlements

	/**
	 * The organizations this user can see.
	 * @param {Array} orgs
	 */
	set orgs(orgs) {
		this._orgs = orgs
	}
	get orgs() { return this._orgs }


}
