import * as React from 'react'
import styled from 'styled-components'

const HelpContainer = styled.div`
	padding: 20px;
`
const ResponsiveImg = styled.img`
	max-width: 100%;
	max-height: 100%; 
`

export default class Doc extends React.PureComponent { // eslint-disable-line

	render() {
		return (
			<HelpContainer>
				<h1>Documentation</h1>

				<h2>Sign Up</h2>

				<div>
				If you've been invited to join a PictorialD organization your profile has already been setup by your administrator.

				All you need to do is login with the e-mail address that your organization has on file.

				You can get started by clicking the login button:
				</div>

				<div>
					<a href="/login" target="blank" >
						<ResponsiveImg src="static/images/docs/login-button.png"/>
					</a>
				</div>


				<h3>PictorialD allows you to fully manage your credentials</h3>

				<ul>
					<li>If your organization e-mail address matches that of your facebook or gmail account, it is easiest if you sign up
					with the Facebook or Google options</li>
					<li>If your organiztion e-mail address does not match, then you must create a new account</li>
				</ul>


				<h4>Creating an Account</h4>

				<ol>
					<li>Click on the "Sign Up" tab</li>
					<li>Enter your e-mail and a password
						<div>
							<ResponsiveImg src="static/images/docs/signup.png"/>
						</div>
					</li>
					<li>Wait for a verifcation e-mail to be delivered</li>
					<li>Click the verification link</li>
					<li>Return to PictorialD and click the "Ok I verified it" button to access your directory
						<div>
							<ResponsiveImg src="static/images/docs/verify-button.png"/>
						</div>
					</li>
				</ol>

				<h2>Update Profile</h2>

				At any time you may edit your profile by clicking "Profile" in the navigation bar at the top of your screen.
				<div>
					<ResponsiveImg src="static/images/docs/profilenav.png"/>
				</div>
				<ul>
					<li>Entering birthdate's for your children will cause their age to show up in the directory</li>
				</ul>

				<h2>View Directory</h2>

				<ol>
					<li>Click on the directory located at the top of your profile page.</li>
					<li>You can search for someone by first or last name using the Filter</li>
				</ol>


				<h2>Troubleshooting</h2>

				<h4>I don't see any directories</h4>

				<div>
					If you see this message it means that you signed up with the wrong e-mail address or you are not in your
					organization's directory. If you think this is an error, send an e-mail an include the e-mail address and
					name of the organization you are trying to access.
				</div>

				<div>
					<ResponsiveImg src="static/images/docs/nodirectories.png" />
				</div>


			</HelpContainer>
		)
	}
}