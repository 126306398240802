import _ from 'lodash'

import React from 'react'
import styled from 'styled-components'
import ContainerDimensions from 'react-container-dimensions'

import { StickyContainer, Sticky } from 'react-sticky'

// import SearchIcon from '@material-ui/icons/Search'
import Contacts from '@material-ui/icons/Contacts'
import ViewList from '@material-ui/icons/ViewList'
import GridOn from '@material-ui/icons/GridOn'

import { withStyles } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip/Tooltip'

import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'

// Icons
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import PersonAdd from '@material-ui/icons/PersonAddTwoTone'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import styles from '../../styles.module.css'
import Card from './Card'
import httpClient from '../../utils/HttpClient'
import Family from '../../entities/Family'
import {getActiveUser} from '../../entities/User'
import SingleCardDialog from './SingleCardDialog'
import EditCardDialog from './EditCardDialog'

import {email, phone, sms, directions} from '../../utils/profileElements'
import Footer, {BottomNav} from '../../components/Footer'
import {hangSnackbarMessage} from '../../components/ConcessionStand'
import IconAction from '../../components/IconAction'

const minCardWidth = 400 // TODO: Detect device and pick best view accordingly
const multiplyPx = x => `${minCardWidth+(minCardWidth * x)}px`

const DirectoryMember = styled.div`

	// Background, Border
	background-color: whitesmoke;
	border: 1px solid gray;
	border-radius: 5px;
	//filter: drop-shadow(1px 1px 3px gray);
	
	// Size; Cards must be proportionate
	width: 600px;
	height: 250px;
	max-height: 400px;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 5px;
	margin-bottom: 10px;
	margin-left: 10px;
	float: left;
	
	@media only screen and (max-width: ${multiplyPx(1)}) {
		// Fill the width and only be as high as needed
		height: 100%;
		max-height: 1000px;
		width: calc(100% - 30px);
	}
	
	// VERTICAL
	//@media only screen and (min-width: 510px) and (max-width: 820px) {
	//	width: calc((100% - 60px) / 2);
	//}
	//@media only screen and (min-width: 820px) and (max-width: 1600px) {
	//	width: calc((100% - 90px) / 3);
	//}
	
	// HORIZONTAL
	@media only screen and (min-width: ${multiplyPx(1)}) and (max-width: ${multiplyPx(2)}) {
		width: calc((100% - 60px) / 2);
	}
	@media only screen and (min-width: ${multiplyPx(2)}) and (max-width: ${multiplyPx(3)}) {
		width: calc((100% - 90px) / 3);
	}
	@media only screen and (min-width: ${multiplyPx(3)}) and (max-width: ${multiplyPx(4)}) {
		width: calc((100% - 120px) / 4);
	}
`

const TopBar = styled.div`
	position: relative;
`
const SearchBox = styled.div`
	background: white;
	z-index: 1;
`
const ActionBar = styled.div`
	position: absolute;
	right: 30px;
	top: 20px;
`

const FooterDiv = styled.div`
	position:fixed;	
  left:0px;
  bottom:0px;
  width:100%;
`

const Page = styled(StickyContainer)`
	overflow-y: auto;
	overflow-x: hidden;
  padding-bottom: 58px; 
`

const CellText = styled.div`
	padding-left: 10px;
`
const NoWrap = styled.div`
  white-space: nowrap;
`

const muStyles = theme => ({
	controls: {
		marginLeft: '15px'
	}
})

class Directory extends React.Component {

	constructor(props, context) {
		super(props, context)

		const {match = {}, history} = this.props

		this.orgId = match.params.orgId
		this.adminOrg = () => history.push(`/admin/${this.orgId}`)

		const emptyFamily = new Family()
		this.state = {
			families: [emptyFamily],
			filtered: [emptyFamily],
			mode: 'card',
			showEditDialog: false,
			showFullscreenDialog: false,
			activeFamily: { family: emptyFamily}
		}

		_.bindAll(this, 'toggleCardDialog', 'toggleEdit', 'persist')
	}

	componentDidMount() {
		this.loadDirectory()
	}

	render() {

		const footerItems = [
			new BottomNav('Cards', 'card', <Contacts/>),
			new BottomNav('List', 'list', <ViewList/>),
			new BottomNav('Photo Grid', 'grid', <GridOn/>)
		]

		const newMode = (mode) => {
			console.log('mode selected:', mode)
			this.setState({mode})
		}
		return (
			<Page className={styles.root}>
				{this.orgDirectory()}
				<FooterDiv>
					<Footer value={this.state.mode} items={footerItems} onChange={newMode}/>
				</FooterDiv>
				{this.cardDialog()}
				<EditCardDialog
					family={this.state.activeFamily.family}
					active={this.state.showEditDialog}
					onSubmit={this.persist}
					onClose={this.toggleEdit}
				/>
			</Page>
		)
	}

	orgDirectory() {
		const { classes } = this.props
		const families = this.state.filtered

		return (
			<div key="directory">
				<Sticky>
					{
						({style}) => {
							return (
								<TopBar>
									<SearchBox style={{...style}}>
										<FormGroup row className={classes.controls} >
											<TextField
												id="filter"
												label="Filter by Name"
												type="filter"
												margin="normal"
												onChange={e => this.filter(e.target.value)}
											/>
										</FormGroup>
									</SearchBox>
									<ActionBar>
										{this.state.orgAdmin ? <IconAction onClick={this.adminOrg} icon={<PersonAdd/>} label={'Administer'}/> : ''}
									</ActionBar>
								</TopBar>
							)
						}
					}
				</Sticky>

				{this.directoryListing(families)}
			</div>
		)
	}

	directoryListing(families) {
		const {mode} = this.state
		if ('card' === mode) {
			return this.cardListing(families)
		}
		if ('grid' === mode) {
			return this.photoListing(families)
		}
		if ('list' === mode) {
			return this.tableListing(families)
		}
	}

	// CARDS

	cardListing(families) {
		return <div key="directory-members" className="members-list">
			{_.map(families, f => this.familyCard(f))}
		</div>
	}

	familyCard(family) {
		return family.isEmpty() ? '' : (
			<DirectoryMember key={family.id}>
				<Card family={family}
					imgSrc={family.pictureUrl}
					editAction={this.toggleEdit.bind(this, family)}
					fullscreenAction={this.toggleCardDialog}
					editable={this.state.orgAdmin}
					// TODO: construct the img string on the server
				/>
			</DirectoryMember>
		)
	}

	// TABLE LIST

	tableListing(families) {
		return <Table styles={{padding: '5px'}}>
			<TableHead>
				<TableRow>
					<TableCell>Family</TableCell>
					<TableCell>Phone</TableCell>
					<TableCell></TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{families.map(f => this.familyRow(f))}
			</TableBody>
		</Table>
	}

	familyRow(f) {
		const o = f.owner.personData
		return <TableRow key={f.id}>
			<TableCell padding={'none'} onClick={() => this.toggleCardDialog(f)}>
				<CellText>{f.directoryLabel}</CellText>
			</TableCell>
			<TableCell padding={'none'}>
				<Tooltip title={'Dial'}>
					<NoWrap>{phone(o.phoneHome || o.phoneMobile)}</NoWrap>
				</Tooltip>
			</TableCell>
			<TableCell>
				<Tooltip title={'Send Text Message'}>{sms(o.phoneMobile)}</Tooltip>
				<Tooltip title={'Send E-Mail'}>{email(o.email)}</Tooltip>
				<Tooltip title={'Get Directions'}>{directions(o.primaryAddress)}</Tooltip>
			</TableCell>
		</TableRow>
	}

	// PHOTO GRID

	photoListing(families) {

		return <>
			<ContainerDimensions>
				{ ({width}) => {
					const tileWidth = (width < 500 ? 150 : 250)
					const cols = Math.floor(width / tileWidth)
					return (
						<GridList cols={cols}>
							{families.filter(f => f.pictureUrl).map(f => this.familyPhotoTile(f))}
						</GridList>
					)
				}}
			</ContainerDimensions>

		</>
	}

	familyPhotoTile(f) {
		const img = f.pictureUrl ? <img src={f.pictureUrl} alt={f.label} /> : <div/>
		return <GridListTile key={f.id} onClick={() => this.toggleCardDialog(f)} cols={1} rows={2}>
			{img}
			<GridListTileBar
				title={f.directoryLabel}
				actionIcon={
					<IconButton>
						<InfoIcon />
					</IconButton>
				}
			/>
		</GridListTile>
	}


	// UI ACTIONS

	handleChange(field, value) {
		this.setState({...this.state, [field]: value})
	}

	toggleEdit(family) {
		let activeFamily = this.state.activeFamily
		if (!this.state.showEditDialog) {
			activeFamily = {family}
			family.on('change', (family) => {
				const activeFamily = {family}
				this.setState({activeFamily})
			})
		}
		this.setState({...this.state, showEditDialog: !this.state.showEditDialog, activeFamily})
	}

	cardDialog() {
		return <SingleCardDialog family={this.state.activeFamily.family}
			active={this.state.showFullscreenDialog}
			onClose={this.toggleCardDialog} />
	}

	toggleCardDialog(family) {
		let activeFamily = this.state.activeFamily
		if (!this.state.showFullscreenDialog) {
			activeFamily = {family}
		}
		this.setState({...this.state, showFullscreenDialog: !this.state.showFullscreenDialog, activeFamily})
	}

	// DIRECTORY OPERATIONS

	loadDirectory() {
		const getFamilies = () => {
			return httpClient.get(`/api/organizations/${this.orgId}/families`)
		}

		getActiveUser().then(u => this.setState({...this.state, orgAdmin: u.isOrgAdmin(this.orgId)}))

		const filterText = this.state.filter || ''
		return httpClient.all([getFamilies()])
			.then(httpClient.spread((familyResp) => {
				let rawFamilies = familyResp.data
				let component = this
				const families = _.map(rawFamilies, (family) => {
					const f = new Family(family)
					f.pictureUrl = f.pictureId && `/api/organizations/${this.orgId}/families/${f.id}/photos/${f.pictureId}`
					f.on('change', (family) => component.setState({activeFamily: {family}}))
					return f
				})
				const sortedFamilies = _.sortBy(this.applyFilter(families, filterText), f => f.directoryLabel)
				const activeFamily = {family: _.first(sortedFamilies)}
				this.setState({...this.state, families: sortedFamilies, filtered: sortedFamilies, activeFamily})
			}))
			.catch((error) => {
				console.log(error)
			})
	}

	filter(text) {
		const filtered = this.applyFilter(this.state.families, text)
		this.setState({...this.state, filtered, filter:text })
	}

	applyFilter(families, text) {
		if (_.isEmpty(text)) {
			return families
		}
		return _.filter(families, f => {
			const words = _.split(_.toLower(text), /\s|,/) //space or comma
			return _.some(f.members, m => {
				const domain = _.toLower(`${m.firstName} ${m.lastName}`)
				return _.every(words, w => _.includes(domain, w))
			})
		})
	}

	persist() {
		const f = this.state.activeFamily.family
		f.on('save', () => {
			hangSnackbarMessage({message: `${f.label} Updated`, variant: 'success'})
			this.setState({...this.state, showEditDialog: false})
			this.loadDirectory()
		})
		Family.persist(f, {orgId: this.orgId})
	}

}

export default withStyles(muStyles)(Directory)