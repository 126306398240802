import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'

import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from 'material-ui-pickers'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import lightBlue from '@material-ui/core/colors/lightBlue'
import yellow from '@material-ui/core/colors/yellow'

import ConcessionStand from './components/ConcessionStand'
import Container from './containers/Container'
import Login from './components/Login'
import Doc from './containers/Doc'
import Home from './containers/Home'
import Directory from './containers/Directory'
import OrgAdmin from './containers/Admin/Organization'

import AuthService from './utils/AuthService'

const auth = new AuthService()
const RoutedContainer = withRouter(Container)

const PrivateRoute = ({component: Component, ...rest}) => {
	return (
		<Route
			{...rest}
			render={(props) => AuthService.loggedIn()
				? <Component {...props} auth={auth} />
				: <Redirect to={{pathname: '/login', state: {from: props.location}}} />
			}
		/>
	)
}

class App extends React.Component {

	render () {
		// We can apply global style concerns here
		const theme = createMuiTheme({
			palette: {
				primary: lightBlue,
				secondary: yellow,
			},
			typography: {
				useNextVariants: true,
				fontSize: 14
			},
			// overrides: {
			// 	MuiFormControl: { // Name of the component ⚛️ / style sheet
			// 	}
			// },
			props: {
				MuiFormControl: {
					margin: 'dense'
				},
				MuiInput: {
					fontSize: 14
				}
			}
			// status: {
			// 	danger: 'orange',
			// },
		})

		return (
			<MuiThemeProvider theme={theme} >
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<div style={{ height: '100%' }}>
						<ConcessionStand />
						<RoutedContainer auth={auth}>
							<Switch>
								<Route path="/login" render={props => <Login {...props}  auth={auth} />} />
								<Route path="/logout" render={props => <Login {...props} logout={true} auth={auth} />} />
								<Route path="/doc" component={Doc}/>

								<PrivateRoute exact path="/" component={Home} />
								<PrivateRoute path="/home" component={Home} />
								<PrivateRoute path="/organizations/:orgId/directory" component={Directory} />
								<PrivateRoute path="/admin/:orgId" component={OrgAdmin} />
							</Switch>
						</RoutedContainer>
					</div>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		)
	}
}

export default App
