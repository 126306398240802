import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import './index.css'
import clientData from './utils/clientData'
// import registerServiceWorker from './registerServiceWorker';

import Raven from 'raven-js'

clientData.fetch().then(_ => {

	if (clientData.isProduction()) {
		Raven.config('https://1d08d0007ab0435b93e5e10785489560@sentry.io/1273737').install() // prod
	} else {
		console.debug('Running in development mode')
		// Raven.config('http://e04f6397fe404069920ab14d37aff7f7@localhost:9000/2').install() // local Docker instance
	}

	const App = require('./App').default
	ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'))
	// registerServiceWorker()
})
