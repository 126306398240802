import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'

import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'

// Icons
import Home from '@material-ui/icons/HomeTwoTone'
import FaceIcon from '@material-ui/icons/Face'
import EditIcon from '@material-ui/icons/EditTwoTone'
import MaximizeIcon from '@material-ui/icons/AspectRatioTwoTone'


import styled from 'styled-components'
import Address from '../../components/Address'
import {email, phone, sms} from '../../utils/profileElements'
import IconAction from '../../components/IconAction'
import DateUtil from '../../utils/DateUtil'


/**
 * <Container>
 *   <Person Profile>
 *   <Family Photo>
 */
const Container = styled.div`
	display: flex;
	flex-direction: ${props => props.vertical ? 'column' : 'row'};
	align-items: flex-start;
	@media only screen and (max-width: 509px) {
		flex-direction: column;
	}
`
const HomeIcon = styled(Home)`
	float: left;
	margin-top: 8px;
`
const HomeInfo = styled.div`
	margin-left: 30px;
`
const HomePhone = styled.div`
	margin: 5px 0px;
`
const PersonProfile = styled.div`
  flex: 0 1 auto;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
`
const CardLabel = styled.h4`
	margin-top: 5px;
	margin-bottom: 5px;
`
const MemberSection = styled.div`
	margin-top: 5px;
	width: 100%;
`

const ChipContainer = styled(ListItem)`
	flex-wrap: wrap;
`

const FamilyPhoto = styled.div`
	flex: 1 1 auto;
  //position: relative; // https://stackoverflow.com/a/39812443/5656782
  max-width: ${props => props.vertical ? '100%' : '50%'};
  padding: 10px;
	justify-content: center;
	@media only screen and (max-width: 509px) {
		max-width: 100%; // Single card view, photos on bottom
	}
`

const ImgPreview = styled.img`
	width: 100%;
	max-height: 227px;
	object-fit: scale-down;
`


const age = birthDate => {
	if (birthDate) {
		const yearsOld = DateUtil.yearsOld(birthDate)
		return (yearsOld > 0 && yearsOld < 18) ? `(${yearsOld})` : ''
	}
	return ''
}

/**
 * Not a pure component because details can change in edit mode and a deep diff
 * is needed here.  TODO: Optimize only if necessary
 */
export default class Card extends React.Component { // eslint-disable-line

	shouldComponentUpdate(nextProps) {
		return nextProps.family !== this.props.family
	}

	render() {
		const {single} = this.props
		const f = this.props.family
		const owner = f.owner.personData
		const photo = this.props.imgSrc ? <ImgPreview src={this.props.imgSrc} /> : undefined

		let cardLabel = ''
		// single == Read Only view of the family (fullscreen-ish)
		if (!single) {
			const actions = []
			if (!this.props.single) {
				if (this.props.editable === true) {
					actions.push(<IconAction key={'edit-' + f.id} icon={<EditIcon/>} tooltip='Edit' onClick={() => this.props.editAction(f)} />)
				}
				actions.push(<IconAction key={'fs-' + f.id} icon={<MaximizeIcon/>} tooltip='Maximize' onClick={() => this.props.fullscreenAction(f)} />)
			}
			cardLabel = <CardLabel>{f.directoryLabel} {actions}</CardLabel>
		}

		const homePhone = owner.phoneHome ? <HomePhone>{phone(owner.phoneHome, {noMargin:true})}</HomePhone> : undefined

		return (
			<Container vertical={single} >
				{this.props.children}
				<PersonProfile>
					{cardLabel}
					<div>
						<HomeIcon/>
						<HomeInfo>
							<Address name="primaryAddress"
								label="Primary Address"
								value={owner.primaryAddress}
								unlabeled={true} />
							{homePhone}
						</HomeInfo>
					</div>
					<MemberSection>
						{this.renderMembers(f.id, [owner, ...f.getNonOwnersSorted()])}
					</MemberSection>
				</PersonProfile>
				{photo && <FamilyPhoto vertical={single}>{photo}</FamilyPhoto>}
			</Container>
		)
	}

	renderMembers(familyId, members) {

		const {single} = this.props
		if (single) {

			let data = {memberDetails:[], chips: []}
			_.reduce(members, (acc, m, i) => {
				const chip = <MemberChip key={i} member={m} isOwner={i === 0} single={single} />
				if (m.email || m.phoneMobile) {
					acc.memberDetails.push(
						<ListItem key={'person'+i}>
							<span>
								<b>{m.firstName}</b> {age(m.birthDate)}
								<div>{sms(m.phoneMobile)} {phone(m.phoneMobile)}</div>
								<div>{email(m.email)} {m.email}</div>
							</span>
						</ListItem>)
				} else {
					acc.chips.push(chip)
				}
				return acc
			}, data)

			return <div key={familyId}>
				{_.map(data.memberDetails, (d,i) => [d, <Divider key={i} />]) }
				<ChipContainer>
					{data.chips}
				</ChipContainer>
			</div>
		} else {
			return _.map(members, (m, i) => <MemberChip key={i} member={m} isOwner={i === 0} single={single} />)
		}
	}
}

Card.propTypes = {
	/** if this card is the only one being displayed (outside a directory) */
	single: PropTypes.bool,
}

const FullDetail = styled.div`
	white-space: nowrap;
	height: 25px;
	margin-bottom: 5px;
`

const NameChip = styled(Chip)`
	height: 25px !important;
	margin: 1px;
	margin-top: 5px;
`

class MemberChip extends React.Component {

	render() {
		const m = this.props.member || {}
		const hasDetails = this.props.isOwner || m.email || m.phoneMobile
		
		const fullDetail = () => <FullDetail>
			{m.firstName} {age(m.birthDate)}
			{phone(m.phoneMobile)}
			{sms(m.phoneMobile)}
			{email(m.email)}
		</FullDetail>
		
		// icon={<FaceIcon />}
		
		return hasDetails ?
			fullDetail() :
			<NameChip label={`${m.firstName} ${age(m.birthDate)}`} variant="outlined" />
	}
}