import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import green from '@material-ui/core/colors/green'
import amber from '@material-ui/core/colors/amber'

import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

let openSnackbarFn

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon,
}

const styles = theme => ({
	success: {
		backgroundColor: green[600],
	},
	error: {
		backgroundColor: theme.palette.error.dark,
	},
	info: {
		backgroundColor: theme.palette.primary.dark,
	},
	warning: {
		backgroundColor: amber[700],
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing.unit,
	},
	message: {
		display: 'flex',
		alignItems: 'center'
	},
})

const defaultState = {open: false, message: '', variant: 'info'}
class ConcessionStand extends React.Component {

	state = defaultState

	componentDidMount() {
		openSnackbarFn = this.openSnackbar.bind(this)
	}

	openSnackbar = ({ message = '', variant = 'info' }) => {
		this.setState({
			open: true,
			message,
			variant
		})
	}

	handleSnackbarClose = () => {
		this.setState(defaultState)
	}

	render() {
		return (
			<Snackbar
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				autoHideDuration={3000}
				onClose={this.handleSnackbarClose}
				open={this.state.open}>
				{this.snackbarContent(this.props)}
			</Snackbar>
		)
	}

	snackbarContent(props) {
		const {message, variant} = this.state
		const {classes = {}} = props
		const Icon = variantIcon[variant]

		return (
			<SnackbarContent
				className={classes[variant]}
				aria-describedby="client-snackbar"
				message={
					<span id="client-snackbar" className={classes.message}>
          	<Icon className={classNames(classes.icon, classes.iconVariant)}/>
						{message}
        	</span>
				}
				action={[
					<IconButton
						key="close"
						aria-label="Close"
						color="inherit"
						onClick={this.handleSnackbarClose}
					>
						<CloseIcon/>
					</IconButton>
				]}
			/>
		)
	}
}

/**
 * Shows a snackbar message to the user at the top of the screen.
 *
 * @param {String} options.message
 * @param {String} [options.variant='info']
 */
export function hangSnackbarMessage(options) {
	openSnackbarFn(options)
}

export default withStyles(styles)(ConcessionStand)